<script setup lang="ts">
/**
 * @file Mobile Static Drawer Modal
 */
import { awaitTransitionEnd } from '@@/bits/dom'
import OzDivider, { OzDividerColorScheme } from '@@/library/v4/components/OzDivider.vue'
import OzOverlay from '@@/library/v4/components/OzOverlay.vue'
import { useScroll } from '@vueuse/core'
import { computed, onMounted, ref, useSlots } from 'vue'

const props = withDefaults(
  defineProps<{
    fullSize?: boolean
    darkMode?: boolean | 'auto'
    xScrim?: boolean
    isScrimSpectral?: boolean
    shouldFadeIn?: boolean
    zIndexClass?: 'z-modal2' | 'z-sidepanel'
    position?: 'fixed' | 'absolute'
    isBodyScrollable?: boolean
    hideScrollbar?: boolean
    shouldAnimateOnAppear?: boolean
    /**
     * Determines whether the divider should be shown
     */
    headerDividerMode?: 'always' | 'on-scroll'
  }>(),
  {
    fullSize: false,
    darkMode: 'auto',
    xScrim: true,
    isScrimSpectral: false,
    shouldFadeIn: true,
    zIndexClass: undefined,
    position: 'fixed',
    isBodyScrollable: true,
    hideScrollbar: false,
    shouldAnimateOnAppear: true,
    headerDividerMode: 'on-scroll',
  },
)

const emit = defineEmits(['scrim-click', 'scrim-esc', 'transition:after-enter'])

const slots = useSlots()

const root = ref<InstanceType<typeof OzOverlay>>()
const modalContainer = ref<HTMLDivElement>()
const isTouchingTopOfScreen = ref(false)

const scrollingContainer = ref<HTMLElement>()
const { y: scrollTop } = useScroll(scrollingContainer)

const xHeaderDivider = computed(() => {
  if (props.headerDividerMode === 'always') return true
  if (props.headerDividerMode === 'on-scroll' && scrollTop.value > 0) return true
  return false
})

onMounted((): void => {
  if (props.fullSize || (modalContainer.value?.clientHeight as number) >= (root.value?.$el.clientHeight as number)) {
    isTouchingTopOfScreen.value = true
  }
  if (modalContainer.value) {
    awaitTransitionEnd(modalContainer.value).then(() => {
      emit('transition:after-enter')
    })
  }
})
</script>

<script lang="ts">
export default {}
</script>

<template>
  <transition ref="root" leave-active-class="out" :duration="200">
    <OzOverlay
      ref="root"
      :class="[
        'overflow-hidden',
        // Push content to bottom of the screen
        'flex',
        'flex-col',
        'justify-end',
        'items-center',
        // Can remove this once Oz page reset has been applied to all pages.
        'font-sans',
      ]"
      :style="{
        paddingTop: 'var(--safe-area-inset-top)',
      }"
      :is-spectral="isScrimSpectral"
      :scrim="xScrim ? 'modal' : null"
      :should-fade-in="shouldFadeIn"
      :z-index-class="zIndexClass"
      :dark-mode="darkMode"
      :position="position"
      @scrim-click="$emit('scrim-click')"
      @scrim-esc="$emit('scrim-esc')"
    >
      <div
        ref="modalContainer"
        :class="[
          {
            'w-full': true,
            'max-h-none': true,
            'min-h-full': fullSize,
            'overflow-auto no-scrollbar flex flex-col': true,
            'modal-container': true,
            'slide-up-down-animated': shouldAnimateOnAppear,
            'shadow-elevation-5': !fullSize,
            'rounded-none': isTouchingTopOfScreen,
            'rounded-3xl rounded-b-none': !isTouchingTopOfScreen,
            'full-size': fullSize,
            'bg-light-ui-100 dark:bg-dark-ui-100': darkMode === 'auto',
            'bg-light-ui-100 ': darkMode === false,
            'bg-dark-ui-100': darkMode === true,
            'pb-safe-inset-bottom': true,
          },
        ]"
      >
        <div :class="['max-h-full flex grow flex-col overflow-hidden']">
          <header v-if="slots.header" :class="[xHeaderDivider ? 'relative' : '']">
            <slot name="header" :scroll-top="scrollTop" />
            <OzDivider
              v-if="xHeaderDivider"
              class="absolute inset-x-0 bottom-0 mx-4"
              :color-scheme="OzDividerColorScheme.Solid"
            />
          </header>
          <div
            v-if="slots.body"
            ref="scrollingContainer"
            :class="{
              'flex grow flex-col p-0': true,
              'overflow-auto': isBodyScrollable,
              'h-full': !isBodyScrollable,
              'scrollbar-thin': !hideScrollbar && darkMode === false,
              'scrollbar-thin-dark': !hideScrollbar && darkMode === true,
              'scrollbar-thin dark:scrollbar-thin-dark': !hideScrollbar && darkMode === 'auto',
              'no-scrollbar': hideScrollbar,
            }"
          >
            <slot name="body" />
          </div>

          <!-- Default slot. Use this to escape from the header/body layout -->
          <slot></slot>

          <slot name="footer" />
        </div>
      </div>
    </OzOverlay>
  </transition>
</template>

<style lang="scss" scoped>
@import '@@/styles/3/modules/responsive';
@import '@@/styles/3/modules/all';

.slide-up-down-animated {
  animation-name: modal-slide-up; // Slide up the modal when showing
  animation-duration: 0.2s;
  // Being an incoming elements and is animated using decelerated easing
  // @see https://material.io/design/motion/speed.html#controlling-speed
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);

  &.full-size {
    animation-duration: 0.4s;
  }

  .out & {
    animation-name: modal-slide-down; // Slide up the modal when showing
    animation-duration: 0.15s;
    // Being an incoming elements and is animated using accelerated easing
    // @see https://material.io/design/motion/speed.html#controlling-speed
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    animation-fill-mode: forwards;
  }
}

@keyframes modal-slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes modal-slide-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}
</style>
